<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->

      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <Logo />

                <div class="invoice-number-date mt-md-0 mt-2">
                  <div
                    class="d-flex align-items-center justify-content-md-end mb-1"
                  >
                    <h4>
                      Proforma
                      <span class="invoice-number">#{{ salesorderId.orderNumber }}</span>
                
                    </h4>
                     
                    
                  </div>

                  <div
                    class="d-flex align-items-center justify-content-md-between mb-2"
                  >
                    <span class="title"> Issued Date: </span>
                    <flat-pickr
                      v-model="salesorderId.issuedDate"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title"> Due Date: </span>
                    <flat-pickr
                      v-model="salesorderId.dueDate"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="mb-lg-1">
                  <h6 class="mb-2">Send To:</h6>

                  <!-- Select Client -->

                  <!-- Selected Client -->
                  <div v-if="custId !== 0" class="mt-1">
                    <b-card-text class="mb-25">
                      {{ custId.otherName }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ custId.surname }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ custId.addressLine1 }}, {{ custId.addressLine2 }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ custId.city }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ custId.tel }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ custId.email }}
                    </b-card-text>
                  </div>
                  <div class="my-2">
                    <h6 class="mb-2">
                      OrderType : {{ getOptionsOrder(orderType) }}
                    </h6>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->

            <b-table
              responsive
              :fields="fields"
              :items="salesorderId.orderItems"
            >
              <template #cell(cost)="data">
                Rs {{ parseFloat(data.item.cost).toFixed(2) }}
              </template>
              <template #cell(price)="data">
                Rs
                {{
                  (
                    data.item.cost * data.item.qty -
                    (data.item.discount === null ? 0 : data.item.discount)
                  ).toFixed(2)
                }}
              </template>
              <template #cell(discount)="data">
                Rs
                {{
                  (data.item.discount === null
                    ? 0
                    : data.item.discount
                  ).toFixed(2)
                }}
              </template>
            </b-table>
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">Salesperson :</span>
                    <span class="ml-75">{{ salesorderId.salesPersonId }} </span>
                  </b-card-text>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Subtotal:</p>
                      <p class="invoice-total-amount">
                        Rs {{ parseFloat(salesorderId.subTotal).toFixed(2) }}
                      </p>
                    </div>

                    <div class="invoice-total-item">
                      <p class="invoice-total-title">VAT</p>
                      <p class="invoice-total-amount">
                        Rs {{ parseFloat(salesorderId.tax).toFixed(2) }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Discount</p>
                      <p class="invoice-total-amount">
                        Rs {{ parseFloat(salesorderId.discount).toFixed(2) }}
                      </p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total:</p>
                      <p class="invoice-total-amount">
                        Rs {{ parseFloat(salesorderId.total).toFixed(2) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              {{ note }}
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printSo"
          >
            Print / Download
          </b-button>

          <b-button
            variant="primary"
            class="mb-75"
            block
            v-if="
              this.salesorderId.status != 3 && this.salesorderId.status != 4
            "
            @click="checkInventory"
          >
            Check Inventory & Confirm
          </b-button>
          <b-button
            variant="primary"
            class="mb-75"
            block
            :to="`/proforma/edit/${this.$route.params.id}`"
          >
            Edit
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

import { mapActions, mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormSelect,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormSelect,
    BCardText,
    BTable,
    BForm,
    BFormGroup,
    BSpinner,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      id: this.$route.params.id,
      optionsCustomer: [],
      optionsProduct: [],
      optionsSalesPerson: [],
      salesPersonId: "",
      leadClientId: "",
      orderType: "",
      dueDate: "",
      issuedDate: "",
      note: "",
      subTotal: 0,
      fields: [
        { key: "itemName", span: "Item Name" },
        { key: "description", span: "DESCRIPTION" },
        { key: "cost", span: "UNIT PRICE (RS)" },
        { key: "qty", span: "QUANTITY" },
        { key: "discount", span: "DISCOUNT" },
        { key: "price", span: "TOTAL" },
      ],
      taxOptions: [
        { value: 0, text: "0%" },
        { value: 1, text: "15%" },
      ],
      tax: 0,
      proformaPerson: "",

      ///order type
      optionsOrderType: [
        { value: null, text: "Please select an option" },
        { value: 0, text: "Fixed Order" },
        { value: 1, text: "Variable Order" },
        { value: 2, text: "OneOff Order" },
      ],
    };
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight();
  },
  computed: {
    ...mapGetters("salesOrderModule", {
      salesorderId: "salesorderId",
      inventoryStatus: "inventoryStatus",
      loading: "loading",
    }),
    ...mapGetters("customerModule", {
      CustomerList: "customers",
      custId: "custId",
      loading: "loading",
    }),
    ...mapGetters("productModule", {
      ProductList: "product",
      loading: "loading",
    }),
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),

    ...mapGetters("invoiceModule", {
      invoices: "invoices",
      loadingInvoice: "loading",
    }),
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    beautifyDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    },
    printSo() {
      window.print();
    },
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleDateString();
    },

    ...mapActions("customerModule", [
      "getCustomersListAction",
      "getCustomersByIDAction",
    ]),
    ...mapActions("productModule", ["getProductListAction"]),
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    ...mapActions("salesOrderModule", [
      "getSalesOrdersByIDAction",
      "checkInventoryAction",
      "updateSalesOrdersAction",
    ]),
    ...mapActions("invoiceModule", ["addInvoicesAction"]),
    addNewItemInItemForm() {
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    error2(error) {
      this.$swal({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    inventoryAvailable() {
      this.$swal({
        title: "Stock Available!",
        text: "Do you want to confirm invoice?",
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.sendInvoice();
        }
      });
    },

    sendInvoice() {
      this.$swal({
        title: "Loading...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });
      let payload = {
        leadClientId: this.salesorderId.leadClientId,
        salesPersonId: this.proformaPerson,
        dueDate: this.salesorderId.dueDate,
        notes: this.salesorderId.note,
        status: 0,
        invoiceType: this.salesorderId.orderType,
        isActive: true,
        discount: this.salesorderId.discount,
        discountType: this.salesorderId.discountType,
        paymentMethod: "string",
        subTotal: this.salesorderId.subTotal,
        tax: this.salesorderId.tax,
        orderId: this.id,
        total: this.salesorderId.total,
        warehouseId: this.salesorderId.warehouseId,
        invoiceItem: this.salesorderId.orderItems.map((event) => ({
          itemId: event.itemId,
          description: event.description,
          quantity: event.qty,
          price: event.price,
          cost: event.cost,
          discountType: event.discountType,
          discount: event.discount,
        })),
      };
      this.addInvoicesAction(payload)
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Invoice confirmed!",
            text: "Your invoice has been created",
            customClass: {
              confirmButton: "btn btn-success",
            },
          }).then(() => {
            this.$router.push("/invoice/view/" + response.data.result.id);
          });
        })
        .catch((error) => {
          this.$swal.close();
          this.error2(error);
        });
    },
    onDemand() {
      this.$swal({
        title: "Stock not available!",
        text: "Send to production or purchase order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, confirm!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.changeStatus();
        }
      });
    },
    changeStatus() {
      this.$swal({
        title: "Loading...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });
      let payload = {
        id: this.id,
        orderNumber: this.salesorderId.orderNumber,
        leadClientId: this.salesorderId.leadClientId,
        salesPersonId: this.proformaPerson,
        issuedDate: this.salesorderId.issuedDate,
        dueDate: this.salesorderId.dueDate,
        note: this.salesorderId.note,
        status: 1,
        orderType: this.salesorderId.orderType,
        isActive: true,
        paymentMethod: "string",
        subTotal: this.salesorderId.subTotal,
        tax: this.salesorderId.tax,
        total: this.salesorderId.total,
        discount: this.salesorderId.discount,
        discountType: this.salesorderId.discountType,
        warehouseId: this.salesorderId.warehouseId,
        orderItems: this.salesorderId.orderItems,
      };
      this.updateSalesOrdersAction(payload)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Product on demand!",
            text: "Please wait for production or purchase order",
            customClass: {
              confirmButton: "btn btn-success",
            },
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((error) => {
          this.error(error);
        });
    },
    checkInventory() {
      this.$swal({
        title: "Checking Inventory",
        html: '<b-spinner span="Loading..."></b-spinner><p>Please wait while we check the inventory</p>',
        showConfirmButton: false,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
          setTimeout(() => {
            this.checkInventoryAction(this.id)
              .then(() => {
                if (this.inventoryStatus.isStockAvailable) {
                  this.inventoryAvailable();
                } else {
                  this.onDemand();
                }
              })
              .catch(() => {
                this.errorInventoryCheck();
              });
          }, 3000);
        },
      }).then(() => {
        this.$swal.close();
      });
    },

    getOptionsOrder(orderType) {
      switch (orderType) {
        case 0:
          return "Fixed Order";
        case 1:
          return "Variable Order";
        case 2:
          return "OneOff Order";
        default:
          return "Please select an option";
      }
    },

    errorInventoryCheck() {
      this.$swal({
        title: "Inventory Check Failed",
        text: "Inventory Check has failed, please try again later",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    // error
    error(error) {
      this.$swal({
        title: "Error!" + error.response.data.title,
        text: error.response.data.errors.request,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },

    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    customerSelected(id) {
      this.getCustomersByIDAction(id);
      this.custId = null;
    },
  },
  async mounted() {
    await this.getCustomersListAction().then(() => {
      this.CustomerList.map((v, i) =>
        this.optionsCustomer.push({
          value: v.id,
          text: `${(v.otherName ??= v.companyName)} ${(v.surname ??= "")}`,
        })
      );
    });
    await this.getSalesOrdersByIDAction(this.id).then(() => {
      this.proformaPerson = this.salesorderId.salesPersonId;
      this.leadClientId = this.salesorderId.leadClientId;
      this.salesPersonId = this.salesorderId.salesPersonId;
      this.issuedDate = this.salesorderId.issuedDate;
      this.dueDate = this.salesorderId.dueDate;
      this.note = this.salesorderId.note;
      this.orderType = this.salesorderId.orderType;
      this.invoiceData.items = this.salesorderId.orderItems.map((event) => ({
        value: event.itemId,
        id: `${event.itemName}`,
        cost: event.cost,
        qty: event.qty,
        tax: event.tax,
        purchase: event.price,
        description: event.description,
      }));
    });
    this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.forEach((element) => {
        if (element.id == this.salesorderId.salesPersonId) {
          this.salesorderId.salesPersonId =
            element.firstName + " " + element.lastName;
        }
      });
    });

    this.getCustomersByIDAction(this.salesorderId.leadClientId);
    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({ value: v.id, text: `${v.name}` })
      );
    });
    await this.getProductListAction().then(() => {
      this.ProductList.map((v, i) => {
        v.qty = 1;
        this.optionsProduct.push({
          value: v.id,
          itemTitle: `${v.name}`,
          cost: v.salePrice,
          tax: v.saleTaxPercentage,
          purchase: v.purchasePrice,
          profit: v.markUpPercentage,
          description: v.description,
        });
      });
    });
  },
  setup() {
    const itemFormBlankItem = {
      item: null,
      value: null,
      cost: 0,
      purchase: 0,
      qty: 0,
      tax: 0,
      profit: 0,
      description: "",
    };

    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: "",
      note: "It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!",
      paymentMethod: null,
    });

    const updateItemForm = (index, val) => {
      console.log(val);
      const { cost, qty, description, purchase, tax, profit, value } = val;
      invoiceData.value.items[index].cost = cost;
      invoiceData.value.items[index].qty = qty;
      invoiceData.value.items[index].value = value;
      invoiceData.value.items[index].tax = tax;
      invoiceData.value.items[index].profit = profit;
      invoiceData.value.items[index].purchase = purchase;
      invoiceData.value.items[index].description = description;
    };

    return {
      invoiceData,
      updateItemForm,
      itemFormBlankItem,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss">
@media print {
  a[href]:after {
    content: none !important;
  }

  html,
  body {
    height: 99% !important;
  }
  // Global Styles
  .invoice-date-title {
    color: black !important;
  }

  span {
    color: black !important;
    font-size: 17.5px !important;
  }

  * {
    color: black !important;
  }
  body {
    background-color: transparent !important;
    font-size: 17.5px !important;
    color: black !important;
  }

  nav.header-navbar {
    display: none;
  }
  .flatpickr-input {
    font-size: 17.5px !important;
    color: black !important;
  }
  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  .content-header {
    display: none !important;
  }

  .invoice-edit-input {
    border: none;
  }

  // Invoice Specific Styles
  .invoice-add-wrapper {
    .row.invoice-add {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
